import store from '@/state/store'


export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['autheasy/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/select-company',
        name: 'Select-company',
        meta: {
            authRequired: true
        },
        component: () => import('../views/pages/account/select-company'),
    },
    {
        path: '/userGetAll',
        name: 'userGetAll',
        component: () => import('../views/pages/account/getall')
    },
    {
        path: '/register',
        name: 'register',
        meta: {
            authRequired: true
        },
        component: () => import('../views/pages/account/register'),
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['autheasy/loggedIn']) {
					store.dispatch('autheasy/resetpassWord');
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
					store.dispatch('autheasy/resetpassWord');
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
                    store.dispatch('auth/logOut')
                } else {
                    if (process.env.VUE_APP_DEFAULT_AUTH === "easyindustria-api") {
						store.dispatch('autheasy/loGout')
					} else {
						store.dispatch('authfack/logout')
					}
                }
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
            },
        },
    },
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/index')
    },
    {
        path: '/cadastros/empresas',
        name: 'empresas',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/empresa/index')
    },
    {
        path: '/cadastros/pessoas',
        name: 'pessoas',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/pessoa/index')
    },
    {
        path: '/cadastros/produtos',
        name: 'produtos',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/produto/index')
    },
    {
        path: '/cadastros/tabela',
        name: 'tabela',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/tabela/index')
    
    },
   
    {
        path: '/cadastros/localestoque',
        name: 'cadastros-localestoque',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/localestoque/index')
    },

    {
        path: '/cadastros/item',
        name: 'item',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/item/index')
    },
    {
        path: '/cadastros/plano',
        name: 'plano',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/plano/index')
    },
    {
        path: '/cadastro/venda',
        name: 'venda',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/cadaastro/venda/index.vue')
    },
    {
        path: '/cadastro/contaCorrente',
        name: 'contaCorrente',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/cadaastro/contaCorrente/index.vue')
    },
    {
        path: '/financeiro/pedidos/blocoK',
        name: 'financeiro.blocoK',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/financeiro/pedidos/blocoK/index')
    },
    {
        path: '/saude',
        name: 'saude',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/saude/index')
    },
    {
        path: '/financeiro/pedidos',
        name: 'financeiro.pedidos',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/financeiro/pedidos/index')
    },
    {
        path: '/financeiro/pedidos/listar-compras',
        name: 'financeiro.paulo',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/financeiro/pedidos/listagens/pre-compras.vue')
    },
    {
        path: '/financeiro/bancos',
        name: 'bancos',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/financeiro/bancos/index')
    },
    {
        path: '/financeiro/agenciaBanc',
        name: 'agencia',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/financeiro/agenciaBanc/index')
    },
    {
        path: '/financeiro/faturamento',
        name: 'faturamento',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/financeiro/faturamento/index')
    },
    {
        path: '/financeiro/contasReceber',
        name: 'contasReceber',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/financeiro/contasReceber/index')
    },
    {
        path: '/financeiro/contasPagar',
        name: 'contasPagar',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/financeiro/contasPagar/index')
    },
    {
        path: '/financeiro/controle_de_caixa',
        name: 'controle_de_caixa',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/financeiro/controle_de_caixa/index')
    },
    {
        path: '/financeiro/controle_cheque',
        name: 'controle_cheque',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/financeiro/controle_cheque/index')
    },
    {
        path: '/financeiro/ordem_servico',
        name: 'ordem_servico',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/financeiro/ordem_servico/index')
    },
    {
        path: '/financeiro/pagamentofornecedor',
        name: 'pagamentofornecedor',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/financeiro/pagamentofornecedor/index')
    },
    {
        path: '/financeiro/recebimentoCliente',
        name: 'pagamentofornecedor',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/financeiro/recebimentoCliente/index')
    },
    {
        path: '/financeiro/transferencia_saldo',
        name: 'transferencia_saldo',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/financeiro/transferencia_saldo/index')
    },
    {
        path: '/financeiro/confirmapagamentos',
        name: 'transferencia_saldo',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/financeiro/confirmapagamentos/index')
    },
    {
        path: '/carga_transporte/embarque',
        name: 'embarque-cadastrar',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/carga_transporte/embarque/index')
    },
    {
        path: '/carga_transporte/veiculo',
        name: 'veiculo-lsitar',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/carga_transporte/veiculo/index')
    },
    {
        path: '/carga_transporte/rotas',
        name: 'rotas-lsitar',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/carga_transporte/rotas/index')
    },
    {
        path: '/carga_transporte/motorista',
        name: 'motorista-lsitar',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/carga_transporte/motorista/index')
    },
    {
        path: '/carga_transporte/motorista',
        name: 'motorista-lsitar',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/carga_transporte/motorista/index')
    },
    {
        path: '/carga_transporte/manutencao',
        name: 'manutencao-lsitar',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/carga_transporte/manutencao/index')
    },
    {
        path: '/carga_transporte/servicos',
        name: 'servicos-listar',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/carga_transporte/servicos/index')
    },
    {
        path: '/carga_transporte/abastecimento',
        name: 'abastecimento-listar',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/carga_transporte/abastecimento/index')
    },
    
    {
        path: '/parametros/tiposmovimento',
        name: 'parametros-tiposmovimento',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/parametros/tiposmovimento/index')
    },
    {
        path: '/parametros/tipospagamento',
        name: 'parametros-tipospagamento',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/parametros/tipospagamento/index')
    },
    {
        path: '/parametros/centrocusto',
        name: 'parametros-centrocusto',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/parametros/centrocusto/index')
    },
    {
        path: '/parametros/centroresultado',
        name: 'parametros-centroresultado',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/parametros/centroresultado/index')
    },
    {
        path: '/parametros/historicopadrao',
        name: 'parametros-historicopadrao',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/parametros/historicopadrao/index')
    },
    {
        path: '/parametros/unidademed',
        name: 'parametros-unidademed',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/parametros/unidademed/index')
       

    },

    {
        path: '/parametros/grupos',
        name: 'parametros-grupos',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/parametros/grupos/index')
       

    },
    {
        path: '/parametros/series',
        name: 'parametros-series',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/parametros/series/index')
       

    },
    {
        path: '/parametros/cidades',
        name: 'parametros-cidades',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/parametros/cidades/index')
       

    },
    {
        path: '/parametros/situacao',
        name: 'parametros-situacao',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/parametros/situacao/index')
       

    },
    {
        path: '/processos/confeccaoProduto',
        name: 'processos-confeccaoProduto',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/processos/confeccaoProduto/index')
    },
    {
        path: '/relatorio/caixa/contas_pagar',
        name: 'caixas1',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/relatorio/caixa/contas_pagar/index')
    },
    {
        path: '/relatorio/caixa/financeiro_custo',
        name: 'caixas2',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/relatorio/caixa/financeiro_custo/index')
    },
    {
        path: '/relatorio/caixa/financeiro_receita',
        name: 'caixas3',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/relatorio/caixa/financeiro_receita/index')
    },
    {
        path: '/relatorio/caixa/list_contas_pagar',
        name: 'caixas4',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/relatorio/caixa/list_contas_pagar/index')
    },
    {
        path: '/relatorio/caixa/periodo',
        name: 'caixas5',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/relatorio/caixa/periodo/index')
    },
    {
        path: '/relatorio/caixa/sangrias_periodo',
        name: 'caixas6',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/relatorio/caixa/sangrias_periodo/index')
    },
    {
        path: '/relatorio/caixa/vendas_funcionario',
        name: 'caixas7',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/relatorio/caixa/vendas_funcionario/index')
    },
    {
        path: '/relatorio/caixa/list_contas_receber',
        name: 'caixas8',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/relatorio/caixa/list_contas_receber/index')
    },
    {
        path: '/relatorio/cheque/cheque_emitido',
        name: 'caixas9',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/relatorio/cheque/cheque_emitido/index')
    },
    {
        path: '/relatorio/cheque/cheque_recebido',
        name: 'caixas10',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/relatorio/cheque/cheque_recebido/index')
    },
    {
        path: '/relatorio/estoque/list_produtos',
        name: 'estoque1',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/relatorio/estoque/list_produtos/index')
    },
    {
        path: '/relatorio/estoque/produto_estoque',
        name: 'estoque2',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/relatorio/estoque/produto_estoque/index')
    },
    {
        path: '/relatorio/estoque/vendas_tipo_produto',
        name: 'estoque3',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/relatorio/estoque/vendas_tipo_produto/index')
    },
    {
        path: '/relatorio/estoque/vendas_quantidade_vendas',
        name: 'estoque4',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/relatorio/estoque/vendas_quantidade_vendas/index')
    },
    {
        path: '/relatorio/estoque/list_cliente',
        name: 'estoque5',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/relatorio/estoque/list_cliente/index')
    },
    {
        path: '/relatorio/estoque/list_fornecedores',
        name: 'estoque6',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/relatorio/estoque/list_fornecedores/index')
    },
    {
        path: '/relatorio/estoque/relatorio_nfe',
        name: 'estoque7',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/relatorio/estoque/relatorio_nfe/index')
    },
    {
        path: '/item/prePedido/insert',
        name: 'estoque8',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/item/insert')
    }
]
